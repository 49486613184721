<template>
  <div :class="containerClass ? 'search_speech' + containerClass : 'spinner__container'">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p v-if="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    containerClass: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>


<style lang="less">
.spinner__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--row {
    flex-direction: row;
  }

  &--column {
    flex-direction: column;
  }

  &--search {
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      @media only screen and (max-width: 995px) {
        height: 60%;
      }
  }
  &--centered {
    position: absolute;
    top: 50%
  }
  
}
.lds-ring {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 2px;
  border: 2px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>